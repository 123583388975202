import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Card, CardContent, Checkbox, FormControlLabel, RadioGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import CancelJobDrawer from '../../../../main-deprecated/views/jobs/jobList/detail/SubComponents/CancelJobDrawer';
import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectIsCancelProcessing,
  selectIsCancelSeriesProcessing,
  selectIsJobOrderDetailLoading,
  selectJobOrderDetail,
} from '../../../store/selectors/jobOrdersSelector';
import theme from '../../../theme/theme';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';
import {
  cancelSeriesJob,
  cancelSingleJob,
  updateExtensionGroup,
  updateJobOrder,
} from '../jobOrdersReducer';

import JOB_TYPE from './constants';

const generateDateTextColor = (isInRange, isCurrentMonth, isSelected) => {
  if (isInRange) {
    if (isSelected) {
      return `${theme?.components?.detailLeft?.palette?.backgroundColor}`;
    }
    return 'primary.main';
  }
  return isCurrentMonth ? 'text.primary' : 'text.secondary';
};
const titleStyle = {
  fontFamily: theme.components.detailLeft.header.typography.fontFamily,
  fontSize: '20px',
  marginTop: '5px',
  marginBottom: '1px',
};

const boldText = {
  fontFamily: theme.components.detailLeft.header.typography.fontFamily,
  fontSize: '16px',
};
const subTitleStyle = {
  fontFamily: theme.components.detailLeft.title.typography.fontFamily,
  fontSize: theme.components.detailLeft.title.typography.fontSize,
  marginTop: '3px',
};

const headerStyle = {
  fontFamily: theme.components.detailLeft.header.typography.fontFamily,
  fontSize: theme.components.detailLeft.header.typography.fontSize,
};

const subText = {
  fontFamily: theme.components.detailLeft.subText.typography.fontFamily,
  fontSize: theme.components.detailLeft.subText.typography.fontSize,
};

const buttonStyle = {
  width: '100%',
  marginTop: 1,
  fontFamily: theme.components.detailLeft.button.typography.fontFamily,
};
const JobOrderDetailLeft = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isJobOrderDetailLoading = useSelector(selectIsJobOrderDetailLoading);
  const isCancelProcessing = useSelector(selectIsCancelProcessing);
  const isCancelSeriesProcessing = useSelector(selectIsCancelSeriesProcessing);
  const user = useSelector(selectUser);
  const jobOrderDetail = useSelector(selectJobOrderDetail);
  const { id } = useParams();

  const [isCancelJobDrawerOpen, setIsCancelJobDrawerOpen] = useState(false);
  const [cancelJobType, setCancelJobType] = useState(null);

  const isExtensionGroupExist = !!jobOrderDetail?.extensionGroup;
  const isExtensionGroupOngoing = !jobOrderDetail?.extensionGroup?.end;
  const corporationName = jobOrderDetail?.corpName;
  const extensionGroupId = jobOrderDetail?.extensionGroup?.id;
  const extensionGroupNumOpenings = jobOrderDetail?.extensionGroup?.numOpenings;
  const numOpenings = jobOrderDetail?.numOpenings;
  const extensionStart = jobOrderDetail?.extensionGroup?.start;
  const extensionEnd = jobOrderDetail?.extensionGroup?.end;
  const scheduledJobData = jobOrderDetail?.extensionGroup?.scheduledOrders;
  const extensionCadence = jobOrderDetail?.extensionGroup?.cadence;

  const capitalizedDays = (extensionCadence || []).map((day) =>
    typeof day === 'string' && day.length > 0
      ? day.charAt(0).toUpperCase() + day.slice(1).toLowerCase()
      : ''
  );
  const [endDateInput, setEndDateInput] = useState();
  const [newSingleOpeningsValue, setNewSingleOpeningsValue] = useState();
  const [newGroupOpeningsValue, setNewGroupOpeningsValue] = useState();
  const [openShift, setOpenShift] = useState(JOB_TYPE.THIS_SHIFT);
  const [dailyValue, setDailyValue] = useState(jobOrderDetail?.start);
  const [isOngoingChecked, setIsOngoingChecked] = useState(isExtensionGroupOngoing);

  const handelCancelJob = (jobInfo) => {
    if (cancelJobType === JOB_TYPE.THIS_SHIFT) {
      dispatch(cancelSingleJob(jobInfo));
    }
    // extensionGroup job order
    else dispatch(cancelSeriesJob({ extensionGroup: extensionGroupId }));
  };

  useEffect(() => {
    if (extensionEnd) {
      setEndDateInput(extensionEnd);
      setIsOngoingChecked(false);
    } else {
      setEndDateInput(extensionStart + 1);
      setIsOngoingChecked(true);
    }
  }, [extensionStart, extensionEnd]);

  const styledCalenderSx = [];

  const handleOngoingCheckChange = (event) => {
    setIsOngoingChecked(event.target.checked);
  };

  const PaperComponent = ({ children }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ margin: '0 auto' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOngoingChecked}
              onChange={handleOngoingCheckChange}
              disabled={!!extensionEnd}
              color="primary"
            />
          }
          label={LanguageConverter('jobDetails.ongoingJob')}
        />
      </Box>
      {children}
    </Box>
  );
  PaperComponent.propTypes = {
    children: PropTypes.node,
  };

  const isSameDay = (epochTime1, epochTime2) => {
    const date1 = new Date(epochTime1);
    const date2 = new Date(epochTime2);

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const handleCancelSingleJob = () => {
    setCancelJobType(JOB_TYPE.THIS_SHIFT);
    setIsCancelJobDrawerOpen(true);
  };

  const handleCancelSeriesJob = () => {
    setCancelJobType(JOB_TYPE.ENTIRE_SERIES);
    setIsCancelJobDrawerOpen(true);
  };

  const shouldDisableBeforeStartDate = (date) =>
    moment(date).isBefore(jobOrderDetail?.extensionGroup?.start);

  const shouldDisableAfterEndDate = (date) =>
    moment(date).isAfter(jobOrderDetail?.extensionGroup?.end || endDateInput);

  const renderDay = (date, selectedDates) => {
    const currentDate = moment(date);
    const isInRange = scheduledJobData?.find((job) => isSameDay(job.date, currentDate.valueOf()));

    const isCurrentMonth = moment().isSame(currentDate, 'month');
    const isSelected = selectedDates.some((selectedDate) =>
      moment(date).isSame(selectedDate, 'day')
    );
    return (
      <Box
        key={currentDate.valueOf()}
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          borderRadius: '50%',
        }}
      >
        <Button
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: generateDateTextColor(isInRange, isCurrentMonth, isSelected),
            zIndex: 1,
            borderRadius: '50%',
            backgroundColor: isSelected ? 'primary.main' : 'transparent',
            width: 39,
            height: 39,
            minWidth: 36,
            ':hover': {
              backgroundColor: theme?.components?.detailLeft?.palette?.buttonColor,
            },
          }}
          onClick={() => {
            const jobInfo = scheduledJobData?.find((job) =>
              isSameDay(job.date, currentDate.valueOf())
            );
            if (jobInfo) {
              navigate(generatePath('/jobDetail/:id', { id: jobInfo?.id }));
            }
            setDailyValue(currentDate.valueOf());
          }}
          disabled={!isInRange}
        >
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            {currentDate.date()}
          </Typography>
          {isInRange && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 4,
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: 'primary.main',
              }}
            />
          )}
        </Button>
      </Box>
    );
  };

  const handleOpenShiftChange = (event) => {
    setOpenShift(event.target.value);
  };

  const isoToEpoch = (isoTime) => {
    const date = new Date(isoTime);
    const epochTime = date.getTime();
    return epochTime;
  };

  const handleUpdateEndDate = () => {
    if (endDateInput && !isOngoingChecked) {
      dispatch(
        updateExtensionGroup({
          extensionGroup: extensionGroupId,
          fields: { end: isoToEpoch(endDateInput) },
        })
      );
    }
  };

  return isJobOrderDetailLoading ? (
    <BackdropCircularProgress />
  ) : (
    <Box sx={{ maxWidth: 300 }}>
      <Typography sx={headerStyle}>{jobOrderDetail?.name}</Typography>
      <Card sx={{ maxWidth: '100%', width: '100%' }}>
        <CardContent sx={{ backgroundColor: theme?.components?.detailLeft?.palette?.greyBg }}>
          <Typography sx={titleStyle}>{LanguageConverter('jobDetails.title')}</Typography>
          <Typography sx={subTitleStyle}>{LanguageConverter('jobDetails.corporation')}</Typography>
          <Typography sx={boldText}>{corporationName}</Typography>
          <Typography sx={subTitleStyle}>{LanguageConverter('jobDetails.shiftTime')}</Typography>
          <Typography sx={boldText}>
            {epochToTimeInReadableFormat(jobOrderDetail?.start)} --{' '}
            {epochToTimeInReadableFormat(jobOrderDetail?.end)}
          </Typography>
          <Typography sx={titleStyle}>{LanguageConverter('jobDetails.location')}</Typography>
          <Typography sx={boldText}>
            {jobOrderDetail?.location?.address},{jobOrderDetail?.location?.city}
          </Typography>
        </CardContent>
      </Card>
      <Box>
        {isExtensionGroupExist ? (
          <>
            <Box>
              <Typography sx={titleStyle}>
                {LanguageConverter('jobDetails.adjustEndDate')}
              </Typography>
              <Typography sx={{ ...subTitleStyle, marginBottom: 1 }}>
                {LanguageConverter('jobDetails.adjustSubtitle')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <table border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td>
                        {' '}
                        <DatePicker
                          label={LanguageConverter('jobDetails.startDate')}
                          value={extensionStart || ''}
                          readOnly
                          renderInput={(params) => <TextField {...params} />}
                          PopperProps={{
                            sx: styledCalenderSx,
                          }}
                        />
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        {' '}
                        <DatePicker
                          label={LanguageConverter('jobDetails.endDate')}
                          value={extensionEnd || endDateInput}
                          shouldDisableDate={(date) => {
                            if (extensionEnd) {
                              return (
                                shouldDisableAfterEndDate(date) ||
                                shouldDisableBeforeStartDate(date)
                              );
                            }
                            return shouldDisableBeforeStartDate(date);
                          }}
                          onChange={(newValue) => {
                            setIsOngoingChecked(false);
                            setEndDateInput(newValue);
                          }}
                          PopperProps={{
                            sx: styledCalenderSx,
                          }}
                          components={{
                            PaperContent: PaperComponent,
                          }}
                          format="DD-MM-YYYY"
                          renderInput={(params) => {
                            const prop = {
                              ...params,
                              inputProps: {
                                ...params.inputProps,
                                value: isOngoingChecked
                                  ? LanguageConverter('jobDetails.ongoing')
                                  : moment(endDateInput).format('MM/DD/YYYY'),
                              },
                            };
                            return <TextField {...prop} />;
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <Button onClick={handleUpdateEndDate} variant="outlined" sx={buttonStyle}>
                          {LanguageConverter('jobDetails.updateEndDate')}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </LocalizationProvider>
            </Box>
            <Box>
              <Typography sx={titleStyle}> {LanguageConverter('jobDetails.dailyView')}</Typography>
              <Typography sx={subTitleStyle}>
                {LanguageConverter('jobDetails.viewManage')}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box className="test123" sx={{ position: 'relative', right: 20 }}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={dailyValue}
                    renderDay={renderDay}
                    onChange={(newValue) => {
                      setDailyValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
          </>
        ) : null}
        <Box>
          <Typography sx={{ ...titleStyle, marginTop: 0 }}>
            {LanguageConverter('jobDetails.changeOpenings')}
          </Typography>
          <Typography sx={subTitleStyle}>
            {isExtensionGroupExist
              ? LanguageConverter('jobDetails.changeSeries')
              : LanguageConverter('jobDetails.changeShift')}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="options"
              name="options"
              value={openShift}
              onChange={handleOpenShiftChange}
              orientation="vertical"
            >
              <FormControlLabel
                value={JOB_TYPE.THIS_SHIFT}
                control={<Radio />}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: 1,
                      borderColor: 'grey.300',
                      borderRadius: 1,
                      color: openShift === JOB_TYPE.THIS_SHIFT ? 'text.primary' : 'text.secondary',
                    }}
                  >
                    <Box sx={boldText}>{LanguageConverter('jobDetails.thisShift')}</Box>
                    <Box>{epochToDateInReadableFormat(jobOrderDetail?.start)}</Box>
                    {openShift === JOB_TYPE.THIS_SHIFT && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '60%',
                        }}
                      >
                        <table border="0" cellSpacing="0" cellPadding="0">
                          <tbody>
                            <tr>
                              <td>
                                <Box sx={subText}>
                                  {LanguageConverter('jobDetails.currentOpenings')}
                                </Box>
                              </td>
                              <td>&nbsp;&nbsp;</td>
                              <td>
                                <Box sx={subText}>
                                  {LanguageConverter('jobDetails.newOpenings')}
                                </Box>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <TextField
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  value={numOpenings}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </td>
                              <td>&nbsp;&nbsp;</td>
                              <td>
                                {' '}
                                <TextField
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  value={newSingleOpeningsValue}
                                  onChange={(event) => {
                                    setNewSingleOpeningsValue(event.target.value);
                                  }}
                                  fullWidth
                                  error={newSingleOpeningsValue < 0}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    )}
                  </Box>
                }
              />
              {isExtensionGroupExist ? (
                <FormControlLabel
                  value={JOB_TYPE.ENTIRE_SERIES}
                  control={<Radio />}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: 1,
                        borderColor: 'grey.300',
                        borderRadius: 1,
                        color:
                          openShift === JOB_TYPE.ENTIRE_SERIES ? 'text.primary' : 'text.secondary',
                      }}
                    >
                      <Box sx={boldText}>{LanguageConverter('jobDetails.series')}</Box>
                      <Box sx={subTitleStyle}>
                        {epochToDateInReadableFormat(extensionStart)}-
                        {extensionEnd
                          ? epochToDateInReadableFormat(extensionEnd)
                          : LanguageConverter('jobDetails.ongoing')}{' '}
                      </Box>
                      <Box sx={subText}>{capitalizedDays?.join(', ')}</Box>
                      {openShift !== JOB_TYPE.THIS_SHIFT && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '60%',
                          }}
                        >
                          <table border="0" cellSpacing="0" cellPadding="0">
                            <tbody>
                              <tr>
                                <td>
                                  <Box sx={subText}>
                                    {LanguageConverter('jobDetails.currentOpenings')}
                                  </Box>
                                </td>
                                <td>&nbsp;&nbsp;</td>

                                <td>
                                  <Box sx={subText}>
                                    {' '}
                                    {LanguageConverter('jobDetails.newOpenings')}
                                  </Box>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    value={extensionGroupNumOpenings}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </td>
                                <td>&nbsp;&nbsp;</td>

                                <td>
                                  {' '}
                                  <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    value={newGroupOpeningsValue}
                                    onChange={(event) => {
                                      setNewGroupOpeningsValue(event.target.value);
                                    }}
                                    error={newGroupOpeningsValue < 0}
                                    fullWidth
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Box>
                      )}
                    </Box>
                  }
                />
              ) : null}
            </RadioGroup>
          </FormControl>
          <Box>
            {' '}
            <Button
              disabled={
                openShift === JOB_TYPE.THIS_SHIFT
                  ? newSingleOpeningsValue === undefined || newSingleOpeningsValue < 0
                  : newGroupOpeningsValue === undefined || newGroupOpeningsValue < 0
              }
              onClick={() => {
                if (openShift === JOB_TYPE.THIS_SHIFT) {
                  dispatch(updateJobOrder({ fields: { numOpenings: newSingleOpeningsValue }, id }));
                } else {
                  dispatch(
                    updateExtensionGroup({
                      extensionGroup: extensionGroupId,
                      fields: { numOpenings: newGroupOpeningsValue },
                      updateOrders: true,
                    })
                  );
                }
              }}
              variant="outlined"
              sx={buttonStyle}
            >
              {LanguageConverter('button.openings.update')}{' '}
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography sx={titleStyle}>{LanguageConverter('jobDetails.cancelShift')}</Typography>
          <Box>
            <Button
              disabled={isCancelProcessing}
              variant="outlined"
              onClick={handleCancelSingleJob}
              sx={buttonStyle}
            >
              {LanguageConverter('jobDetails.thisShift')}
            </Button>
            <br />
            {isExtensionGroupExist ? (
              <Button
                disabled={isCancelSeriesProcessing}
                variant="outlined"
                onClick={handleCancelSeriesJob}
                sx={buttonStyle}
              >
                {LanguageConverter('jobDetails.allShifts')}
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
      <CancelJobDrawer
        jobId={id}
        numOpenings={numOpenings}
        start={cancelJobType === JOB_TYPE.THIS_SHIFT ? jobOrderDetail?.start : extensionStart}
        jobType={cancelJobType}
        user={user}
        isOpen={isCancelJobDrawerOpen}
        onClose={() => setIsCancelJobDrawerOpen(false)}
        handleCancelJob={handelCancelJob}
      />
    </Box>
  );
};

export default JobOrderDetailLeft;
