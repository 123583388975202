import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

import { FEED_BACK } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { limitFillRatioTo100Percent } from '../../helperFunctions';

const useStyles = makeStyles({
  fillRatioWrapper: { width: '250px', display: 'flex', alignItems: 'center' },
  fillRatioInner: { minWidth: 35 },
  fillRatioText: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'left',
    marginRight: '5px',
  },
  fillRatioProgressWrap: { width: '100%' },
  activeBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
  },
  blockLabel: {
    color: 'gray',
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '8px',
  },
  blockData: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'left',
  },
});

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 20,
  border: '1px solid black',
  marginLeft: '5px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: FEED_BACK.GREEN_70,
  },
}));

const ActiveAndUpcomingJobMetrics = ({ jobOrder }) => {
  const classes = useStyles();

  const numOfPendingInvites = jobOrder.placements.filter(
    (e) => e.state.status === 'pendingEmployeeAcceptance'
  ).length;

  return (
    <>
      <Box className="block">
        <Box className="title"> {LanguageConverter('jobOrder.fillRate')} </Box>
        <Box className={classes.fillRatioWrapper}>
          <Box className={classes.fillRatioInner}>
            <Typography className={classes.fillRatioText}>{`${limitFillRatioTo100Percent(
              jobOrder?.fillRatio
            )}%`}</Typography>
          </Box>
          <Box className={classes.fillRatioProgressWrap}>
            <BorderLinearProgress
              variant="determinate"
              value={limitFillRatioTo100Percent(jobOrder?.fillRatio)}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box className={classes.activeBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.openings')}
          </Typography>
          <Typography className={classes.blockData}>{jobOrder.numOpenings}</Typography>
        </Box>
        <Box className={classes.activeBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.placements')}
          </Typography>
          <Typography className={classes.blockData}>{jobOrder.placementsCount}</Typography>
        </Box>
        <Box className={classes.activeBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.pendingInvites')}
          </Typography>
          <Typography className={classes.blockData}>{numOfPendingInvites}</Typography>
        </Box>
      </Box>
    </>
  );
};

ActiveAndUpcomingJobMetrics.propTypes = {
  jobOrder: PropTypes.shape({
    fillRatio: PropTypes.number,
    numOpenings: PropTypes.number,
    placementsCount: PropTypes.number,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ActiveAndUpcomingJobMetrics;
