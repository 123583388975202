import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, IconButton, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import theme from '../../../../../../main-deprecated/theme';
import TrashcanIcon from '../../../../../assets/icons/TrashcanIcon';

const menuItemStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' };

const DeletePlacementMenuButton = ({
  onDeleteFromOne,
  onDeleteFromAll,
  extensionGroupId,
  isBackfill,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!extensionGroupId || isBackfill) {
      onDeleteFromOne();
      return;
    }

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDeleteOne = useCallback(() => {
    onDeleteFromOne();
    handleClose();
  }, [onDeleteFromOne]);

  const handleOnDeleteAll = useCallback(() => {
    onDeleteFromAll();
    handleClose();
  }, [onDeleteFromAll]);

  return (
    <Box sx={{ alignSelf: 'center' }}>
      <IconButton onClick={handleClick} data-testid="removeCandidateButton">
        <TrashcanIcon width={16} height={18} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleOnDeleteOne} sx={menuItemStyle}>
          <Typography sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
            {LanguageConverter('jobOrder.roster.removeFromThisRoster')}
          </Typography>
          <Typography>
            {LanguageConverter('jobOrder.roster.removeTheEmployeeJustFromThisShift')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleOnDeleteAll} sx={menuItemStyle}>
          <Typography sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
            {LanguageConverter('jobOrder.roster.removeFromAllRoster')}
          </Typography>
          <Typography>
            {LanguageConverter('jobOrder.roster.removeTheEmployeeFromAllShiftInThisSeries')}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

DeletePlacementMenuButton.propTypes = {
  onDeleteFromOne: PropTypes.func,
  onDeleteFromAll: PropTypes.func,
  extensionGroupId: PropTypes.string,
  isBackfill: PropTypes.bool,
};

export default DeletePlacementMenuButton;
