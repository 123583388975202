export const BACKFILL_VALUES = {
  ALLOW: 'allowBackfill',
  DONT_ALLOW: 'dontAllowBackfill',
};

export const SHIFT_TIME_ALERT_TYPES = {
  OT: 'ot',
  MAX: 'max',
};

export const TIME_FIELDS_ERRORS = {
  shiftLength: 'error.jobCreate.shiftLength',
};
