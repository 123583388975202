import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import theme from '../../theme';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const SELECT_DROPDOWN_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SelectDropdownFilter = ({
  field,
  operation,
  options,
  disableClearable,
  placeholder,
  onValueChange,
  reformatValue,
  getOptionLabel,
  startAdornmentIcon,
  initialValue = {},
  wrapperSx,
}) => {
  let initOption = null;

  if (initialValue && initialValue.value) {
    if (typeof initialValue.value === 'object') {
      initOption = options.find(
        (option) =>
          initialValue?.value[field] ===
          (reformatValue ? reformatValue(option.value) : option)[field]
      );
    } else {
      initOption = options.find(
        (option) =>
          initialValue?.value === (reformatValue ? reformatValue(option.value) : option)[field]
      );
    }
  }

  const [value, setValue] = useState(initOption);

  const [open, setOpen] = React.useState(false);

  const styleForFormControl = {
    '& .MuiOutlinedInput-root': {
      background: 'white',
      padding: `${value ? 0 : 2}px 2px !important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme?.button?.palette?.primary,
      fontFamily: PRIMARY_FONT['400'],
      opacity: 1,
    },
    '& .MuiInputBase-inputAdornedEnd': {
      background: value && theme?.button?.palette?.primary,
      color: value && 'white',
      height: '6px',
      borderRadius: '6px',
    },

    border: `1px solid ${theme?.searchBoxForFilter?.borderColor?.focus}`,
    borderRadius: '5px',
    justifyContent: 'center',
    height: '28px',
    width: '150px',
  };

  const handleChange = (event, newValue) => {
    if (reformatValue) {
      onValueChange([
        {
          value: newValue ? reformatValue(newValue.value) : null,
          field,
          operation,
        },
      ]);
    } else {
      onValueChange([{ value: newValue ? newValue.value : null, field, operation }]);
    }

    setValue(newValue);
  };

  const handleClear = () => {
    setValue(null);
    handleChange(null, null);
  };

  return (
    <FormControl sx={{ ...styleForFormControl, ...wrapperSx }}>
      <Autocomplete
        onChange={handleChange}
        value={value}
        disableClearable={disableClearable}
        popupIcon={null}
        clearIcon={null}
        noOptionsText="No results found. Try searching for something else."
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
              marginTop: '2px',
            }}
            placement="auto-start"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              display: 'flex',
              gap: '8px',
            }}
          >
            {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
              ),
              endAdornment: (
                <>
                  {value ? (
                    <ClearIcon
                      onClick={handleClear}
                      style={{ cursor: 'pointer', width: '12px', marginLeft: '2px' }}
                    />
                  ) : (
                    <AddIcon
                      style={{
                        color: theme?.button?.palette?.primary,
                        opacity: 1,
                        width: '13px',
                        height: '13px',
                        marginLeft: '2px',
                      }}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, val) =>
          option.name === val.name ||
          (reformatValue && reformatValue(option.value)[field] === val[field])
        }
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options || []}
        getOptionLabel={(option) => getOptionLabel(option) || ''}
      />
    </FormControl>
  );
};

SelectDropdownFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  field: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ value: PropTypes.shape({}) }),
  ]),
  operation: PropTypes.string,
  onValueChange: PropTypes.func,
  reformatValue: PropTypes.func,
  onSubmitApiCallData: PropTypes.shape({}),
  getOptionLabel: PropTypes.func,
  startAdornmentIcon: PropTypes.node,
  wrapperSx: PropTypes.shape({}),
  disableClearable: PropTypes.bool,
};

export { SELECT_DROPDOWN_BACKGROUND, SelectDropdownFilter };
