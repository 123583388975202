import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Modal, Typography } from '@mui/material';

import Button, { BUTTON_TYPE } from '../../../components/Button';
import { PRIMARY_COLOR } from '../../../theme/colorConstants';
import theme from '../../../theme/theme';
import {
  capitalizeToShortWeekday,
  minutesFromMidnightToReadableTime,
  reformatDateToDDMMYYY,
} from '../../../utils';
import { getCadence, isStartDateAndEndDateEqual, ONGOING_KEY } from '../helpers';

const titleStyling = {
  color: theme?.components?.createJobConfirmationModal?.palette?.title?.color,
  fontFamily: theme?.components?.createJobConfirmationModal?.typography?.title?.fontFamily,
  fontSize: theme?.components?.createJobConfirmationModal?.typography?.title?.fontSize,
  mt: 1,
};

const headerStyling = {
  fontFamily: theme?.components?.createJobConfirmationModal?.typography?.header?.fontFamily,
  fontSize: theme?.components?.createJobConfirmationModal?.typography?.header?.fontSize,
  mt: 2,
};

const headTextStyling = {
  fontFamily: theme?.components?.createJobConfirmationModal?.typography?.headerText?.fontFamily,
  fontSize: theme?.components?.createJobConfirmationModal?.typography?.headerText?.fontSize,
  lineHeight: theme?.components?.createJobConfirmationModal?.typography?.headerText?.lineHeight,

  mt: 1,
};
const textStyling = {
  fontFamily: theme?.components?.createJobConfirmationModal?.typography?.text?.fontFamily,
  fontSize: theme?.components?.createJobConfirmationModal?.typography?.text?.fontSize,
  lineHeight: theme?.components?.createJobConfirmationModal?.typography?.text?.lineHeight,
  mt: 1,
};

const ConfirmModal = ({ isOpen, onClose, onSubmit }) => {
  const formState = useFormState();
  const currentFormValues = formState?.values;
  const isOngoing = currentFormValues?.dateRange?.[1] === ONGOING_KEY;

  const smallScreen = useMediaQuery('(max-width:1100px)');

  const confirmationData = {
    position: currentFormValues?.positionTemplate?.name,
    location: currentFormValues?.location?.address,
    department: currentFormValues?.department?.description,
    startDate: reformatDateToDDMMYYY(currentFormValues?.dateRange?.[0]),
    endDate: isOngoing ? 'Ongoing' : reformatDateToDDMMYYY(currentFormValues?.dateRange?.[1]),
    startTime: minutesFromMidnightToReadableTime(currentFormValues?.startTime?.value),
    endTime: minutesFromMidnightToReadableTime(currentFormValues?.endTime?.value),
    openings: currentFormValues?.numOpenings,
    cadence: getCadence(currentFormValues?.daysOfWeek)
      ?.map((item) => capitalizeToShortWeekday(item))
      .join(', '),
    candidates: currentFormValues?.candidates?.length > 0 && currentFormValues?.candidates,
    requireEmployerApproval: currentFormValues?.requireEmployerApproval,
    allowBackFill: currentFormValues?.allowBackfill === 'allowBackfill' ? 'Yes' : 'No',
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          position: 'relative',
          width: smallScreen ? '80vw' : '50vw',
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: 24,
          p: 1,
          maxHeight: '90vh',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            maxHeight: 'calc(90vh - 16px)',
            overflowY: 'auto',
            padding: '50px',
          }}
        >
          <Typography sx={titleStyling}>
            {LanguageConverter('job.create.confirmation.header')}
          </Typography>
          <Typography sx={headerStyling}>
            {LanguageConverter('job.create.confirmation.positionDetails')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: smallScreen ? '20px' : '80px',
            }}
          >
            <Box>
              <Typography sx={headTextStyling}>
                {LanguageConverter('job.create.confirmation.position')}
              </Typography>
              <Typography sx={textStyling}>{confirmationData?.position}</Typography>
            </Box>
            <Box>
              <Typography sx={headTextStyling}>
                {LanguageConverter('job.create.confirmation.location')}
              </Typography>
              <Typography sx={textStyling}>{confirmationData?.location}</Typography>
            </Box>
            <Box>
              <Typography sx={headTextStyling}>
                {LanguageConverter('job.create.confirmation.department')}
              </Typography>
              <Typography sx={textStyling}>{confirmationData?.department}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: smallScreen ? '20px' : '80px',
            }}
          >
            <Box>
              <Typography sx={headerStyling}>
                {LanguageConverter('job.create.confirmation.dates')}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box>
                  <Typography sx={headTextStyling}>
                    {LanguageConverter('job.create.confirmation.startDate')}
                  </Typography>
                  <Typography sx={textStyling}>{confirmationData?.startDate}</Typography>
                </Box>
                <Box sx={{ marginLeft: theme.spacing(3) }}>
                  <Typography sx={headTextStyling}>
                    {LanguageConverter('job.create.confirmation.endDate')}
                  </Typography>
                  <Typography sx={textStyling}>{confirmationData?.endDate}</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography sx={headerStyling}>
                {LanguageConverter('job.create.confirmation.times')}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box>
                  <Typography sx={headTextStyling}>
                    {LanguageConverter('job.create.confirmation.startTime')}
                  </Typography>
                  <Typography sx={textStyling}>{confirmationData?.startTime}</Typography>
                </Box>
                <Box sx={{ marginLeft: theme.spacing(2) }}>
                  <Typography sx={headTextStyling}>
                    {LanguageConverter('job.create.confirmation.endTime')}
                  </Typography>
                  <Typography sx={textStyling}>{confirmationData?.endTime}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography sx={headerStyling}>
                {LanguageConverter('job.create.confirmation.openingsHeader')}
              </Typography>
              <Typography sx={textStyling}>
                {confirmationData?.openings} {LanguageConverter('job.create.confirmation.openings')}
              </Typography>
              <Typography sx={textStyling}>{confirmationData?.cadence}</Typography>
            </Box>
          </Box>
          {confirmationData?.requireEmployerApproval && (
            <Box>
              <Typography sx={headerStyling}>
                {LanguageConverter('job.create.confirmation.requireEmployerApproval')}
              </Typography>
              <Typography sx={textStyling}>
                {LanguageConverter('job.create.confirmation.requireApprovalText')}
              </Typography>
            </Box>
          )}
          {!isStartDateAndEndDateEqual(currentFormValues) && (
            <Box>
              <Typography sx={headerStyling}>
                {LanguageConverter('job.create.confirmation.allowBackFill')}
              </Typography>
              <Typography sx={textStyling}>{confirmationData?.allowBackFill}</Typography>
            </Box>
          )}

          {confirmationData?.candidates?.length > 0 && (
            <Box>
              <Typography sx={headerStyling}>
                {LanguageConverter('job.create.confirmation.invitedEmployees')}
              </Typography>
              <Typography
                sx={{
                  ...textStyling,
                  width: '100%',
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipse',
                  lineHeight: '22px',
                }}
              >
                <Box>
                  {confirmationData?.candidates?.map((person) => (
                    <Box key={person.id} style={{ marginRight: '10px' }}>
                      {person.name}
                    </Box>
                  ))}
                </Box>
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              margin: theme.spacing(5),
              gap: 2,
            }}
          >
            <Button
              text={LanguageConverter('job.create.edit')}
              onClick={onClose}
              sx={{
                color: theme?.components?.createJobConfirmationModal?.palette?.editButton?.color,
                padding: theme.spacing(2),
                backgroundColor:
                  theme?.components?.createJobConfirmationModal?.palette?.editButton
                    ?.backgroundColor,
                border: '1px solid black',
              }}
              hoverColor={PRIMARY_COLOR[20]}
            />
            <Button
              data-testid="modalSubmitButton"
              type={BUTTON_TYPE.SUBMIT}
              text={LanguageConverter('job.create.post')}
              onClick={onSubmit}
              sx={{
                backgroundColor:
                  theme?.components?.createJobConfirmationModal?.palette?.postButton?.color,
                padding: theme.spacing(2),
              }}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ConfirmModal;
