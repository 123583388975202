import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  isShiftCompleted,
  isShiftStarted,
} from '../../utils';

import { JOB_ORDER_TIME_STATUSES } from './jobOrderConstants';

export const groupJobOrdersByStartDate = (jobOrders) =>
  jobOrders.reduce((acc, curr) => {
    const weekDay = epochToDayOfWeek(curr.start);
    const date = epochToDateInReadableFormat(curr.start);
    if (acc[date]) {
      acc[date].cards.push(curr);
    } else {
      acc[date] = { weekDay, cards: [curr] };
    }
    return acc;
  }, {});

export const getTimeStatus = (start, end) => {
  if (!isShiftStarted(start)) {
    return JOB_ORDER_TIME_STATUSES.UPCOMING;
  }
  if (isShiftCompleted(end)) {
    return JOB_ORDER_TIME_STATUSES.COMPLETED;
  }
  return JOB_ORDER_TIME_STATUSES.ACTIVE;
};

export const getUniqObjectsByKey = (array) => [
  ...new Map(array.map((item) => [item?.key, item])).values(),
];
